<script>
import AppDivider from './app-divider';

const NAVIGATION_LINKS = Object.freeze([
	{ label: 'Horses', url: '/horses' },
	{ label: 'About', url: '/homepage/about' },
	{ label: 'Services', url: '/services' },
	{ label: 'Facilities', url: '/facilities' },
	{ label: 'Contact', url: '/contact/new' },
	{ label: 'Home', url: '/' },
]);

export default {
	components: {
		AppDivider,
	},
	data() {
		return {
			NAVIGATION_LINKS,
			isOpen: false,
		};
	},
	watch: {
		isOpen() {
			document.body.style.overflow = this.isOpen ? 'hidden' : 'initial';
		},
	},
};
</script>

<template>
	<nav class='tw-fixed tw-w-full tw-z-50'>
		<div class='tw-px-1 tw-py-2 tw-text-white tw-bg-primary-900 tw-flex tw-flex-col tw-items-center'>
			<div class='tw-w-full tw-max-w-screen-xl tw-flex tw-items-center tw-justify-between'>
				<a href='/' class='tw-text-white tw-flex tw-items-center'>
					<img
					alt='Go to Ekberg Sporthorses.com home page'
					src='../assets/images/logo.png'
					style='height: 24px; width: 24px;'
					/>
					<span class='tw-ml-2 tw-text-2xl ekb-font-family'>
						Ekberg Sporthorses
					</span>
				</a>
				<span class='tw-hidden md:tw-inline-block tw-flex tw-items-center tw-justify-end'>
					<a
					v-for='link in NAVIGATION_LINKS'
					:key='link.label'
					:href='link.url'
					class='tw-ml-5 tw-text-white'
					>
						{{ link.label }}
					</a>
				</span>
				<span class='md:tw-hidden'>
					<font-awesome-icon
					v-if='!isOpen'
					icon='bars'
					size='2x'
					@click='isOpen = true'
					/>
					<font-awesome-icon
					v-if='isOpen'
					icon='times'
					size='2x'
					@click='isOpen = false'
					/>
				</span>
			</div>
		</div>
		<div v-if='isOpen' class='tw-h-screen tw-p-2 tw-bg-white'>
			<div
			v-for='(link, index) in NAVIGATION_LINKS'
			:key='link.label'
			class='tw-flex tw-flex-col tw-items-center'
			>
				<app-divider
				v-if='index !== 0'
				color='primary-100'
				class='tw-max-w-xs tw-min-w-xs'
				>
				</app-divider>
				<a
				:href='link.url'
				class='tw-block tw-my-3 tw-text-2xl tw-text-primary-900'
				@click='isOpen = false'
				>
					{{ link.label }}
				</a>
			</div>
		</div>
	</nav>
</template>

// ! Do not use "scope" until webpack configuration issues are figured out.
<style>
	.ekb-font-family {
		font-family: 'Fredericka the Great', sans-serif;
	}
</style>
