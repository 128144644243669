<script>
export default {
	props: {
		color: {
			default: 'primary-500',
			type: String,
		},
		height: {
			default: 1,
			type: [Number, String],
		},
	},
};
</script>

<template>
	<div :class='`tw-w-full tw-bg-${color}`' :style='`height: ${Number(height)}px`'></div>
</template>
