import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add(
	faBars,
	faTimes,
);

export const mount = ({ component, id }) => {
	document.addEventListener('DOMContentLoaded', () => {
		const mountableElements = [...document.querySelectorAll(`#${id}`)];
		mountableElements.forEach(element => {
			new Vue({
				render: h => h(component)
			}).$mount(element);
		});
	});
};
